.neo-sip-tile {
  padding: 2.25rem 1rem;
  text-decoration: none;
}

.neo-sip-tile-container {
  display: flex;
  align-items: center;
  gap: 32px;

  img {
    flex-shrink: 0;
    width: 2.8rem;
    height: 2.8rem;
    color: var(--neo-text-color);

    html.dark & {
      filter: invert(1);
    }
  }

  .neo-tile-info {
    h3 {
      margin: 8px 0;
      color: var(--neo-text-color);
    }

    p {
      margin: 8px 0 0;
      min-height: 32px;
      font-size: 0.8rem;
      color: var(--neo-text-color);
    }
  }
}
