.neo-theme-switch {
  position: relative;
}

.neo-theme-switch-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  color: var(--neo-text-color);
  border: none;
  flex-shrink: 0;
  gap: 0.5rem;
  border-radius: 9999px;
  font-weight: 500;
  background-color: transparent;
  vertical-align: middle;
  transition: all 0.2s;
  font-size: 0.75rem;
  cursor: pointer;

  &:hover {
    background-color: var(--neo-hover-bg-color);
  }
}

.neo-theme-switch-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.neo-theme-switch-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 800;
  padding: 0.25rem 0.5rem;
  opacity: 0;
  visibility: hidden;
  color: var(--neo-text-color);
  background-color: var(--neo-bg-color);
  transition:
    opacity 0.2s,
    visibility 0.2s;
  transform: translate(-50%, 60px);
  font-size: 0.75rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
  font-weight: 500;
  white-space: nowrap;
}

.neo-theme-switch-button:hover .neo-theme-switch-tooltip {
  opacity: 1;
  visibility: visible;
}
