.neo-dropdown-menu.neo-snowfall-menu {
  & > div {
    min-width: 8rem;

    button {
      width: 8rem;
      display: flex;
      align-items: center;

      img {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}
