.neo-app-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 803;
  width: 250px;
  height: 100vh;
  background-color: var(--neo-bg-color);
  border-right: 1px solid var(--neo-border-color);
  transition: transform 0.3s ease-in-out;

  &.collapsed {
    transform: translateX(-100%);
  }

  &.expanded {
    transform: translateX(0);
  }
}

.neo-lurchi {
  position: absolute;
  inset: 0;
  z-index: 800;
  background: url('../../assets/svgs/lurchi.svg') no-repeat left bottom;
}

.neo-sidebar-content {
  height: 85vh;
  margin-top: 72px;
  overflow-y: auto;
  padding: 0 16px;

  & nav ul {
    list-style: none;
    padding: 0;
  }

  @media (height <=1200px) {
    height: 80vh;
  }

  @media (height <= 1000px) {
    height: 75vh;
  }

  @media (height <= 800px) {
    height: 70vh;
  }

  @media (height <= 600px) {
    height: 60%;
  }

  @media (height <= 500px) {
    height: 50%;
  }
}

.neo-sidebar-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 806;
  padding: 0 24px 24px;

  svg {
    fill: var(--neo-text-color);
    width: 1.25rem;
    height: 1.25rem;
  }

  a {
    color: var(--neo-text-color);
    text-decoration: underline;

    &:hover {
      color: var(--neo-hover-color);
    }
  }
}

.neo-sidebar-slot {
  z-index: 807;
}

.neo-sidebar-imprint {
  color: var(--neo-text-color);
  z-index: 807;

  &:hover {
    color: var(--neo-hover-color);
  }
}

.neo-nav-bar {
  position: fixed;
  top: 76px;
  left: 0;
  z-index: 802;
  width: 100vw;
  height: 76px;
  margin: 0;
  background-color: var(--neo-bg-color);
  border-bottom: 1px solid var(--neo-border-color);
  display: flex;
  align-items: center;

  .neo-nav-menu-button {
    width: 76px;
    margin-right: 20px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .neo-nav-bar-breadcrumbs {
    display: flex;
    align-items: center;
    height: 76px;
  }
}
