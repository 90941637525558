.neo-dropdown-menu.neo-profile-button {
  & > button {
    background-color: #535b67; /* Salamander Grey */
    color: white;
  }

  & > div {
    padding: 0;
  }
}

.neo-profile-menu-header {
  padding: 0.75rem 1.25rem;
  background-color: var(--neo-element-bg-color);
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 1px solid var(--neo-border-color);

  p {
    margin: 0;
    color: var(--neo-text-color);
  }

  .version {
    text-align: right;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--neo-text-color);
  }

  .details {
    font-size: 0.875rem;
    color: var(--neo-text-color);
  }

  .name {
    font-weight: 600;
    color: var(--neo-text-color);
  }
}

.neo-profile-menu-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.75rem;
  gap: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  background-color: var(--neo-bg-color);
  border: none;

  &:hover {
    background-color: var(--neo-hover-bg-color);
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}
