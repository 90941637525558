:root {
  --neo-bg-color: #fff;
  --neo-element-bg-color: #f9fafb;
  --neo-border-color: #e1e1e1;
  --neo-text-color: #3f3f46;
  --neo-hover-text-color: #3f3f46;
  --neo-hover-bg-color: #f9fafb;
  --neo-active-bg-color: #e5e7eb;
  --neo-active-text-color: #1f2937;
  --neo-text-color-tooltip: #d4d4d4;
  --neo-bg-color-tooltip: #1e1e1e;
  --neo-accent-color: #007acc;
  --neo-success-color: #10b981;
  --neo-warn-color: #f97316;
  --neo-error-color: #e3342f;
  --neo-bg-red: #f20909;
}

:root.dark {
  --neo-bg-color: #1e1e1e;
  --neo-element-bg-color: #252526;
  --neo-border-color: #3c3c3c;
  --neo-text-color: #d4d4d4;
  --neo-hover-text-color: #f9fafb;
  --neo-hover-bg-color: #3f3f46;
  --neo-active-bg-color: #374151;
  --neo-active-text-color: #f9fafb;
  --neo-text-color-tooltip: #3f3f46;
  --neo-bg-color-tooltip: #fff;
}

html,
body,
#app {
  height: 100vh;
  padding: 0;
}

.neo-body {
  overflow-y: hidden;
  background-color: var(--neo-bg-color);
}

.scrollbar-hide {
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.neo-screen-reader {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.neo-dropdown-menu {
  position: relative;
  display: inline-flex;

  /* Dropdown Button */
  & > button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    color: var(--neo-text-color);
    border-radius: 50%;
    background-color: transparent;
    font-size: 14px;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: var(--neo-hover-bg-color);
    }
  }

  /* Dropdown Menu */
  & > div {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 806;
    display: none;
    padding: 0.5rem;
    min-width: 240px;
    background-color: var(--neo-bg-color);
    border-radius: 4px;
    box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
    border: 1px solid var(--neo-border-color);
    opacity: 0;
    transition:
      opacity 0.3s,
      margin 0.3s;

    /* Dropdown items */
    button {
      width: 100%;
      padding: 0.5rem 1rem;
      color: var(--neo-text-color);
      border-radius: 4px;
      font-size: 0.875rem;
      background-color: transparent;
      transition: background-color 0.3s ease;
      cursor: pointer;
      border: none;

      & + button {
        margin-top: 0.5rem;
      }
    }
  }

  &.open > div {
    display: block;
    opacity: 1;
  }
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  src: url('./assets/fonts/RobotoCondensed-Light.ttf');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  src: url('./assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  src: url('./assets/fonts/RobotoCondensed-Bold.ttf');
}

* {
  font-family: 'Roboto Condensed', sans-serif;
  scrollbar-width: thin;
}

/* sip */
piral-portal > * {
  /* Gilt für jedes Element, das ein Kind von piral-portal ist */
  height: 100%;

  /* TabPanels padding normalize */
  .p-tabpanels {
    padding: 16px 0 0;
  }

  /* TabPanels padding */
  .tab-px {
    padding-left: 16px;
    padding-right: 16px;
  }
}
