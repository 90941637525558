.neo-sip-menu-item {
  list-style: none;
  z-index: 801;
}

.neo-menu-link-wrapper {
  position: relative;
  z-index: 801;
  display: block;
  width: 100%;
}

.neo-menu-link {
  z-index: 801;
  display: flex;
  align-items: center;
  color: var(--neo-text-color);
  gap: 1rem;
  padding: 0 8px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
  .neo-accordion-content & {
    padding: 0 8px 0 16px;
  }

  &:hover {
    background-color: var(--neo-hover-bg-color);
  }

  &.active {
    background-color: var(--neo-active-bg-color);
    color: var(--neo-active-text-color);
  }
}

.neo-menu-icon {
  z-index: 801;
  width: 16px;
  height: 16px;

  html.dark & {
    filter: invert(1);
  }
}

.neo-menu-content {
  z-index: 801;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.neo-menu-title {
  font-size: 1rem;
  z-index: 801;
}

.neo-menu-badge {
  padding: 0.125rem 0.5rem;
  color: white; /* ausnahmsweise ohne var */
  background-color: var(--neo-bg-red);
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.neo-tooltip {
  position: relative;
}

.neo-tooltip-text {
  display: none;
  width: 169px;
  padding: 0.25rem 0.5rem;
  color: var(--neo-text-color-tooltip);
  transform: translate(40px, -50%);
  background-color: var(--neo-bg-color-tooltip);
  border-radius: 4px;
  font-size: 0.7rem;
  white-space: nowrap;
}

.neo-tooltip:hover .neo-tooltip-text {
  display: block;
}

.neo-menu-accordion {
  z-index: 801;
  position: relative;

  & button {
    padding: 0 8px;
  }
}

.neo-accordion-toggle {
  z-index: 801;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--neo-text-color);
  gap: 1rem;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  text-align: left;
  border: none;

  &:hover {
    background-color: var(--neo-hover-bg-color);
  }

  &.active {
    color: var(--neo-active-text-color);
  }
}

.neo-accordion-icon {
  z-index: 801;
  width: 12px;
  height: 12px;
  background: url('../../../assets/svgs/down-arrow.svg') no-repeat center center;
  background-size: contain;
  transition: scale 0.3s ease;

  &:hover {
    filter: invert(0.5);
  }

  html.dark & {
    filter: invert(1);
  }

  &.expanded {
    scale: 1 -1;
  }
}

.neo-accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0px;

  &.open {
    /* TODO: ersetzen durch height: calc-size(auto) sobald Firefox das kann */
    max-height: 100vh;
  }
}
