.neo-toast-icon {
  width: 1rem;
  height: 1rem;
  margin-top: 0.125rem;

  &.green {
    color: var(--neo-success-color);
  }

  &.orange {
    color: var(--neo-warn-color);
  }

  &.red {
    color: var(--neo-error-color);
  }
}

.neo-close-icon {
  width: 0.875rem;
  height: 0.875rem;
}

.neo-toast {
  z-index: 808;
  width: 24rem;
  border: 1px solid var(--neo-border-color);
  background-color: var(--neo-bg-color);
  border-radius: 4px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);
}

.neo-toast-container {
  display: flex;
  padding: 1rem;
}

.neo-toast-type {
  flex-shrink: 0;
}

.neo-toast-content {
  margin-left: 1rem;

  h3 {
    color: var(--neo-text-color);
    margin: auto;
  }

  h3 + div {
    font-size: 0.875rem;
    color: #4b5563; /* ohne var */

    html.dark & {
      color: #9ca3af; /* ohne var */
    }
  }

  div {
    padding-top: 1px;
  }
}

.neo-toast-close {
  margin-left: auto;

  .neo-toast-close-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: transparent;
    color: var(--neo-text-color);
    border: none;
    flex-shrink: 0;
    border-radius: 4px;
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
      color: var(--neo-hover-color);
    }

    &:focus {
      outline: none;
    }
  }
}
