.neo-info-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px;
}

.neo-app-content {
  box-sizing: border-box;
  position: absolute;
  padding: 1rem;
  background-color: var(--neo-bg-color);
  @media (width >=1024px) {
    top: 76px;
    right: 0;
    width: calc(100% - 250px);
    height: calc(100vh - 76px);
  }

  @media (width <= 1024px) {
    top: 152px;
    width: 100%;
    height: calc(100vh - 152px);
  }
}

.neo-app-content-children {
  height: inherit;
  overflow: auto;
  background-color: var(--neo-bg-color);
}

.neo-notification-host {
  position: absolute;
  bottom: 0;
  left: 0.75rem;
  z-index: 805;
  width: 24rem;
  margin-bottom: 0.75rem;
}

.neo-dashboard {
  display: grid;
  margin: 1rem;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.neo-dashboard-tile {
  display: flex;
  background-color: var(--neo-element-bg-color);
  border: 1px solid var(--neo-border-color);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
  border-radius: 4px;
  transition:
    transform 0.1s ease,
    box-shadow 0.1s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 15px rgb(0 0 0 / 10%);
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  html.dark & {
    &:hover {
      box-shadow: 0 10px 15px rgb(92 92 92 / 10%);
    }
  }
}

.neo-breadcrumbs {
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 0;
  height: 100%;
  padding: 0;
}

.neo-breadcrumbs-item {
  font-size: 1rem;
  color: var(--neo-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.neo-breadcrumbs-item-link {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: var(--neo-text-color);

  &:hover {
    color: var(--neo-hover-text-color);
  }
}

.neo-breadcrumbs-svg {
  width: 0.625rem;
  height: 0.625rem;
  color: var(--neo-text-color);
  flex-shrink: 0;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  overflow: visible;
}
